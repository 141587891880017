import { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const phrases = [
  'AI innovation',
  'Process improvement',
  'Automation',
  'Quality control',
  'Idea generation',
  'Content creation',
  'Data analysis',
  'Machine learning',
  'Neural networks',
  'Deep learning'
];

export function TypewriterText() {
  const el = useRef<HTMLSpanElement>(null);
  const typed = useRef<Typed | null>(null);

  useEffect(() => {
    if (el.current) {
      typed.current = new Typed(el.current, {
        strings: phrases,
        typeSpeed: 50, // Typing speed in milliseconds
        backSpeed: 30, // Backspacing speed in milliseconds
        startDelay: 300, // Time before typing starts
        backDelay: 1500, // Time before backspacing starts
        loop: true,
        cursorChar: '|',
        autoInsertCss: true,
        smartBackspace: true
      });
    }

    return () => {
      if (typed.current) {
        typed.current.destroy();
      }
    };
  }, []);

  return (
    <span ref={el} className="typed-text" />
  );
}