interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

export function updateSEO({ 
  title = 'Versantus AI | AI Consulting Services',
  description = 'Expert AI consulting services helping businesses leverage artificial intelligence for growth and innovation. Partner with Versantus AI for strategic AI implementation.',
  image = 'https://images.unsplash.com/photo-1639322537228-f710d846310a?auto=format&fit=crop&w=1200&q=80',
  url = 'https://versantus.ai'
}: SEOProps = {}) {
  // Update title
  document.title = title;
  
  // Update meta tags
  const metaTags = {
    'description': description,
    'og:title': title,
    'og:description': description,
    'og:image': image,
    'og:url': url,
    'twitter:title': title,
    'twitter:description': description,
    'twitter:image': image,
    'twitter:url': url,
  };

  Object.entries(metaTags).forEach(([name, content]) => {
    // Update OpenGraph and Twitter tags
    const elements = document.querySelectorAll(`meta[property="${name}"], meta[name="${name}"]`);
    elements.forEach(element => {
      element.setAttribute('content', content);
    });
  });
}