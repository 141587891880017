import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import { ReactNode } from 'react';

interface AnimatedBackgroundProps {
  children: ReactNode;
  backgroundImage?: string;
  overlayColors?: {
    from: string;
    via?: string;
    to: string;
  };
  patternOpacity?: number;
  gradientOpacity?: number;
}

export function AnimatedBackground({
  children,
  backgroundImage = "url('https://images.unsplash.com/photo-1639322537228-f710d846310a?auto=format&fit=crop&w=1200&q=80')",
  overlayColors = {
    from: 'from-primary-gunmetal/80',
    via: 'via-primary-gunmetal/60',
    to: 'to-primary-gunmetal',
  },
  patternOpacity = 0.3,
  gradientOpacity = 0.2,
}: AnimatedBackgroundProps) {
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  // Smooth spring animation config for background only
  const springConfig = { damping: 25, stiffness: 150 };
  
  // Create spring animations for background movement only
  const bgRotateX = useSpring(useTransform(mouseY, [0, window.innerHeight], [2, -2]), springConfig);
  const bgRotateY = useSpring(useTransform(mouseX, [0, window.innerWidth], [-2, 2]), springConfig);
  
  const bgMoveX = useSpring(useTransform(mouseX, [0, window.innerWidth], [-10, 10]), springConfig);
  const bgMoveY = useSpring(useTransform(mouseY, [0, window.innerHeight], [-10, 10]), springConfig);

  const handleMouseMove = (event: React.MouseEvent) => {
    const { clientX, clientY } = event;
    mouseX.set(clientX);
    mouseY.set(clientY);
  };

  return (
    <div className="absolute inset-0 overflow-hidden" onMouseMove={handleMouseMove}>
      {/* Animated Background */}
      <motion.div 
        className="absolute inset-0"
        initial={{ scale: 1.2, opacity: 0 }}
        animate={{ scale: 1, opacity: 0.4 }}
        transition={{ duration: 1.5 }}
        style={{
          x: bgMoveX,
          y: bgMoveY,
        }}
      >
        <motion.div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat w-[120%] h-[120%] -left-[10%] -top-[10%]"
          style={{
            backgroundImage,
            rotateX: bgRotateX,
            rotateY: bgRotateY,
            transformPerspective: 1000,
          }}
          animate={{
            scale: [1, 1.2, 1],
            filter: ['brightness(0.7)', 'brightness(1.2)', 'brightness(0.7)']
          }}
          transition={{
            duration: 15,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
          }}
        />
        <div 
          className={`absolute inset-0 bg-gradient-to-b ${overlayColors.from} ${overlayColors.via} ${overlayColors.to}`}
        />
      </motion.div>

      {/* Content Container */}
      <div className="relative z-10 h-full">
        {children}
      </div>

      {/* Animated Overlay Pattern */}
      <motion.div
        className="absolute inset-0 pointer-events-none z-20"
        style={{
          backgroundImage: `radial-gradient(circle at 1px 1px, rgba(240, 20, 110, ${patternOpacity}) 1px, transparent 0)`,
          backgroundSize: '30px 30px',
          x: useTransform(bgMoveX, value => value * -0.2),
          y: useTransform(bgMoveY, value => value * -0.2),
        }}
        animate={{
          backgroundPosition: ['0% 0%', '100% 100%']
        }}
        transition={{
          duration: 15,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "linear"
        }}
      />

      {/* Additional Animated Overlay */}
      <motion.div
        className="absolute inset-0 pointer-events-none z-20 bg-gradient-to-r from-primary-raspberry/20 to-primary-amethyst/20"
        style={{
          x: useTransform(bgMoveX, value => value * -0.1),
          y: useTransform(bgMoveY, value => value * -0.1),
        }}
        animate={{
          opacity: [gradientOpacity, gradientOpacity * 2, gradientOpacity]
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut"
        }}
      />
    </div>
  );
}