import { motion } from 'framer-motion';
import { Shield, ArrowUpRight, CheckCircle2, Lock, FileCheck, Scale, AlertCircle } from 'lucide-react';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import { AnimatedBackground } from '../../components/AnimatedBackground';

export function Governance() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="bg-primary-gunmetal text-white relative h-[600px]">
        <AnimatedBackground>
          <div className="py-24 h-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
              <div className="grid lg:grid-cols-2 gap-12 items-center h-full">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                    <Shield className="w-8 h-8 text-primary-raspberry" />
                  </div>
                  <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
                    AI governance & security
                  </h1>
                  <p className="mt-6 font-opensans text-lg text-gray-200 max-w-xl">
                    Ensure responsible AI deployment with robust security measures and governance frameworks.
                  </p>
                  <div className="mt-10">
                    <Link to="/contact">
                      <Button variant="secondary" size="lg" className="group">
                        Discuss your security needs
                        <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                      </Button>
                    </Link>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </AnimatedBackground>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Key Areas Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {[
              {
                icon: Lock,
                title: "Security",
                description: "Implement robust security measures to protect AI systems and data."
              },
              {
                icon: Scale,
                title: "Compliance",
                description: "Ensure adherence to relevant regulations and industry standards."
              },
              {
                icon: FileCheck,
                title: "Documentation",
                description: "Maintain comprehensive documentation of AI systems and processes."
              },
              {
                icon: AlertCircle,
                title: "Risk management",
                description: "Identify and mitigate potential risks in AI implementation."
              }
            ].map((area, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white dark:bg-primary-gunmetal rounded-xl p-6 shadow-soft"
              >
                <div className="bg-primary-raspberry/10 rounded-xl p-3 w-12 h-12 flex items-center justify-center mb-4">
                  <area.icon className="w-6 h-6 text-primary-raspberry" />
                </div>
                <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-2">
                  {area.title}
                </h3>
                <p className="font-opensans text-gray-600 dark:text-gray-300">
                  {area.description}
                </p>
              </motion.div>
            ))}
          </div>

          {/* Detailed Sections */}
          <div className="grid lg:grid-cols-2 gap-16">
            {/* Framework Section */}
            <div>
              <h2 className="font-outfit text-3xl font-bold text-primary-gunmetal dark:text-white mb-8">
                Governance framework
              </h2>
              <div className="space-y-6">
                {[
                  {
                    title: "Policy development",
                    description: "Creation of comprehensive AI governance policies tailored to your organization."
                  },
                  {
                    title: "Ethical guidelines",
                    description: "Development of ethical principles and guidelines for AI implementation."
                  },
                  {
                    title: "Monitoring systems",
                    description: "Implementation of systems to monitor AI performance and compliance."
                  },
                  {
                    title: "Training programs",
                    description: "Staff training on AI governance and security best practices."
                  }
                ].map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className="flex items-start space-x-4"
                  >
                    <CheckCircle2 className="w-6 h-6 text-primary-raspberry flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-2">
                        {item.title}
                      </h3>
                      <p className="font-opensans text-gray-600 dark:text-gray-300">
                        {item.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Security Section */}
            <div>
              <h2 className="font-outfit text-3xl font-bold text-primary-gunmetal dark:text-white mb-8">
                Security measures
              </h2>
              <div className="bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft">
                <div className="space-y-6">
                  {[
                    {
                      title: "Data protection",
                      points: [
                        "Encryption at rest and in transit",
                        "Access control mechanisms",
                        "Regular security audits",
                        "Incident response planning"
                      ]
                    },
                    {
                      title: "System security",
                      points: [
                        "Network security protocols",
                        "Authentication systems",
                        "Vulnerability assessments",
                        "Security monitoring"
                      ]
                    }
                  ].map((section, index) => (
                    <div key={index} className="space-y-4">
                      <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white">
                        {section.title}
                      </h3>
                      <ul className="space-y-3">
                        {section.points.map((point, pointIndex) => (
                          <li key={pointIndex} className="flex items-center space-x-3">
                            <CheckCircle2 className="w-5 h-5 text-primary-raspberry flex-shrink-0" />
                            <span className="font-opensans text-gray-600 dark:text-gray-300">{point}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-primary text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-10"></div>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative">
          <h2 className="font-outfit text-4xl font-bold mb-6">
            Secure your AI implementation
          </h2>
          <p className="font-opensans text-lg text-gray-100 mb-10">
            Let's build a robust governance framework that ensures responsible and secure AI deployment.
          </p>
          <Link to="/contact">
            <Button variant="secondary" size="lg" className="group">
              Get started
              <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
}