import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowUpRight, ArrowLeft } from 'lucide-react';
import { Button } from '../../components/Button';
import { AnimatedBackground } from '../../components/AnimatedBackground';

const caseStudies = {
  'badminton-trials': {
    title: "Badminton Horse Trials Alexa Skill",
    description: "Creating an innovative voice-enabled experience for equestrian fans to access live event updates and streaming media through Alexa.",
    challenge: "Badminton Horse Trials needed a modern way to deliver real-time event information and audio content to their global audience.",
    solution: "We developed a custom Alexa skill that provides live updates, results, and audio streaming from the event. The skill integrates with their existing content management system and streaming infrastructure.",
    outcome: "The Alexa skill has enhanced fan engagement by providing hands-free access to event information and live audio content, reaching a wider audience through voice technology.",
    image: "/images/case-studies/badminton.jpg",
    industry: "Sports & Entertainment",
    metrics: [
      { label: "Voice interaction", value: "Seamless" },
      { label: "Live updates", value: "Real-time" },
      { label: "Audio streaming", value: "Integrated" }
    ]
  },
  'custom-gpts': {
    title: "Custom GPTs for content generation",
    description: "Developing specialized GPT models for automated content creation, enhancing productivity and maintaining brand consistency.",
    challenge: "Organizations needed efficient ways to generate high-quality, consistent content while maintaining their unique brand voice and style guidelines.",
    solution: "We developed custom GPT models trained on company-specific content, style guides, and brand documentation. These models were integrated into existing content workflows through user-friendly interfaces.",
    outcome: "The custom GPTs significantly reduced content creation time while maintaining consistent brand voice and quality. Content teams reported 60% faster production times and improved content consistency.",
    image: "/images/case-studies/custom-gpts.jpg",
    industry: "AI & Content",
    metrics: [
      { label: "Production time", value: "60% faster" },
      { label: "Content consistency", value: "Improved" },
      { label: "Team efficiency", value: "Enhanced" },
      { label: "Brand alignment", value: "Maintained" }
    ]
  },
  'audley-villages': {
    title: "Drupal AI Tools for Audley",
    description: "Enhancing content management and user experience through AI-powered tools integrated into Audley Villages' Drupal-based website.",
    challenge: "Audley Villages needed to streamline their content management processes and improve the personalization of their digital experience for prospective residents.",
    solution: "We developed a suite of AI-powered tools integrated with their Drupal CMS, focusing on content optimization and user journey enhancement.",
    outcome: "The new system has significantly improved content workflow efficiency and enabled more personalized user experiences across their digital platforms.",
    image: "/images/case-studies/audley.jpg",
    industry: "Retirement Living",
    metrics: [
      { label: "Content workflow improvements", value: "Streamlined" },
      { label: "User experience", value: "Enhanced" },
      { label: "Content personalization", value: "Automated" }
    ]
  },
  'guides-for-brides': {
    title: "Marketing Automation for Guides for Brides",
    description: "Streamlining content workflows and improving SEO performance through intelligent automation for the UK's leading wedding platform.",
    challenge: "Guides for Brides wanted to improve their content management efficiency and strengthen their organic search presence in a competitive market.",
    solution: "We implemented AI-driven automation tools for content optimization and SEO, integrated with their existing marketing workflows.",
    outcome: "The platform now benefits from more efficient content management and improved search visibility across key wedding-related terms.",
    image: "/images/case-studies/guides-for-brides.jpg",
    industry: "Wedding Industry",
    metrics: [
      { label: "Marketing workflow", value: "Automated" },
      { label: "SEO optimization", value: "Enhanced" },
      { label: "Content efficiency", value: "Improved" }
    ]
  },
  'open-access-oxford': {
    title: "User Research Analysis for Open Access Oxford",
    description: "Using AI to analyze user behavior patterns and improve content discovery across Oxford's open access platforms.",
    challenge: "Open Access Oxford needed to better understand user behavior and improve content discoverability across their digital resources.",
    solution: "We deployed AI-powered analytics tools to analyze user interactions and provide actionable insights for content strategy.",
    outcome: "The insights led to improved content organization and discovery features, enhancing the overall user experience.",
    image: "/images/case-studies/oxford.jpg",
    industry: "Education",
    metrics: [
      { label: "User insights", value: "Data-driven" },
      { label: "Content discovery", value: "Enhanced" },
      { label: "Platform usability", value: "Improved" }
    ]
  },
  'mhr-development': {
    title: "MHR Development Enhancement",
    description: "Enhancing platform capabilities through AI-powered development solutions and system optimisation.",
    challenge: "MHR needed to improve their development processes and platform performance while maintaining high quality standards.",
    solution: "We implemented AI-driven development tools and automated testing systems to enhance code quality and deployment efficiency.",
    outcome: "The new AI-powered development workflow has significantly improved code quality and reduced deployment times.",
    image: "/images/case-studies/mhr.jpg",
    industry: "Software Development",
    metrics: [
      { label: "Development speed", value: "Increased" },
      { label: "Code quality", value: "Enhanced" },
      { label: "Deployment time", value: "Reduced" },
      { label: "Testing coverage", value: "Expanded" }
    ]
  }
};

export function CaseStudy() {
  const { slug } = useParams<{ slug: string }>();
  const study = slug ? caseStudies[slug as keyof typeof caseStudies] : null;

  if (!study) {
    return <div>Case study not found</div>;
  }

  return (
    <div className="pt-20">
      {/* Back Link */}
      <div className="bg-white dark:bg-primary-gunmetal border-b border-gray-200 dark:border-primary-eggplant">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <Link 
            to="/case-studies"
            className="inline-flex items-center text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to case studies
          </Link>
        </div>
      </div>

      {/* Hero Section */}
      <section className="bg-primary-gunmetal text-white relative">
        <AnimatedBackground backgroundImage={`url('${study.image}')`}>
          <div className="py-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <div className="inline-block px-4 py-1 rounded-full bg-primary-raspberry/10 text-primary-raspberry font-opensans text-sm font-medium mb-6">
                  {study.industry}
                </div>
                <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6">
                  {study.title}
                </h1>
                <p className="font-opensans text-lg text-gray-200 max-w-2xl">
                  {study.description}
                </p>
              </motion.div>
            </div>
          </div>
        </AnimatedBackground>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-3 gap-12">
            {/* Metrics */}
            <div className="lg:col-span-1">
              <div className="sticky top-24">
                <h2 className="font-outfit text-2xl font-bold text-primary-gunmetal dark:text-white mb-8">
                  Key metrics
                </h2>
                <div className="grid gap-6">
                  {study.metrics.map((metric, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="bg-white dark:bg-primary-gunmetal rounded-xl p-6 shadow-soft"
                    >
                      <div className="text-3xl font-bold text-primary-raspberry mb-2">
                        {metric.value}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-300">
                        {metric.label}
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="lg:col-span-2 space-y-12">
              <div>
                <h2 className="font-outfit text-2xl font-bold text-primary-gunmetal dark:text-white mb-4">
                  The challenge
                </h2>
                <p className="text-gray-600 dark:text-gray-300">
                  {study.challenge}
                </p>
              </div>

              <div>
                <h2 className="font-outfit text-2xl font-bold text-primary-gunmetal dark:text-white mb-4">
                  Our solution
                </h2>
                <p className="text-gray-600 dark:text-gray-300">
                  {study.solution}
                </p>
              </div>

              <div>
                <h2 className="font-outfit text-2xl font-bold text-primary-gunmetal dark:text-white mb-4">
                  The outcome
                </h2>
                <p className="text-gray-600 dark:text-gray-300">
                  {study.outcome}
                </p>
              </div>

              <div className="pt-8">
                <Link to="/contact">
                  <Button variant="primary" size="lg" className="group">
                    Start your AI journey
                    <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}