import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import { Brain, Zap, AlertCircle, ArrowUpRight } from 'lucide-react';
import { Button } from '../components/Button';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

export function HI() {
  const containerRef = useRef(null);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  // Smooth spring animation config
  const springConfig = { damping: 25, stiffness: 150 };
  
  // Create spring animations
  const moveX = useSpring(mouseX, springConfig);
  const moveY = useSpring(mouseY, springConfig);
  
  // Dynamic wave distortion effect
  const handleMouseMove = (event) => {
    const container = containerRef.current;
    if (!container) return;
    
    const { clientX, clientY } = event;
    const { left, top } = container.getBoundingClientRect();
    
    // Get relative position within the container
    const relativeX = clientX - left;
    const relativeY = clientY - top;
    
    mouseX.set(relativeX);
    mouseY.set(relativeY);
  };

  return (
    <div>
      {/* Hero Section with Mouse-Interactive Background */}
      <section 
        ref={containerRef}
        className="relative min-h-[40vh] mt-20 overflow-hidden" 
        style={{ backgroundColor: '#FFB800' }}
        onMouseMove={handleMouseMove}
      >
        <div className="absolute inset-0" style={{ backgroundColor: '#FFB800' }}></div>
        
        {/* Animated dot pattern that responds to mouse */}
        <motion.div 
          className="absolute inset-0 pointer-events-none" 
          style={{ 
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(240, 20, 110, 0.25) 1px, transparent 0)',
            backgroundSize: '20px 20px',
            zIndex: 1,
            x: useTransform(moveX, value => Math.sin(value * 0.01) * -10),
            y: useTransform(moveY, value => Math.sin(value * 0.01) * -10),
          }}
        />
        
        {/* Wave effect - dynamic circles that follow mouse */}
        <motion.div
          className="absolute w-64 h-64 rounded-full opacity-20 pointer-events-none"
          style={{ 
            background: 'radial-gradient(circle, rgba(240, 20, 110, 0.3) 0%, rgba(240, 20, 110, 0) 70%)',
            x: useTransform(moveX, value => value - 100),
            y: useTransform(moveY, value => value - 100),
            filter: 'blur(10px)'
          }}
        />
        
        {/* Second wave effect with different size and opacity */}
        <motion.div
          className="absolute w-80 h-80 rounded-full opacity-15 pointer-events-none"
          style={{ 
            background: 'radial-gradient(circle, rgba(240, 20, 110, 0.2) 0%, rgba(240, 20, 110, 0) 70%)',
            x: useTransform(moveX, value => value - 160),
            y: useTransform(moveY, value => value - 160),
            filter: 'blur(15px)',
            scale: 1.5
          }}
        />
        
        {/* Hero content */}
        <div className="relative z-10 flex h-full py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold text-primary-gunmetal leading-tight mb-4">
                Say HI to AI with Versantus
              </h1>
              <p className="font-opensans text-lg text-primary-gunmetal/80 max-w-2xl mt-6">
                At Versantus, we believe that artificial intelligence (AI) is a powerful tool that, when combined with human intelligence, can drive innovation and efficiency.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-16">
            {/* Left Column */}
            <div>
              <h2 className="font-outfit text-3xl font-bold text-primary-gunmetal dark:text-white mb-8">
                The power of AI & HI combined
              </h2>
              <div className="space-y-6 text-gray-600 dark:text-gray-300">
                <p>
                  Large Language Models (LLMs) like ChatGPT excel at tasks such as summarising lengthy documents, analysing spreadsheet data, and generating code for websites. They can assist in planning meetings, holidays, or even creating shopping lists for dinner parties.
                </p>
                <p>
                  However, AI has its limitations: it may struggle with nuanced writing, producing consistent images, or performing tasks requiring fine motor skills. We recognise the vast opportunities AI presents to organisations while understanding that certain applications may involve risks, costs, or simply be premature for implementation.
                </p>
                <p>
                  By integrating our expertise in website and application development with AI-driven planning, design, coding, and testing, we enhance development processes and reduce costs. Our AI consultation services empower organisations to innovate, optimise workflows, and scale confidently.
                </p>
              </div>

              <div className="mt-12">
                <Link to="/contact">
                  <Button variant="primary" size="lg" className="group">
                    Start your AI journey
                    <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                  </Button>
                </Link>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-8">
              {[
                {
                  icon: Brain,
                  title: "AI Capabilities",
                  description: "Our AI solutions excel at tasks like document analysis, code generation, and process automation, delivering efficiency and accuracy at scale."
                },
                {
                  icon: Zap,
                  title: "Voice Assistant Development",
                  description: "We offer voice assistant skill development, creating custom voice experiences that transform user interactions with your brand."
                },
                {
                  icon: AlertCircle,
                  title: "Responsible Implementation",
                  description: "Our commitment to leveraging AI responsibly ensures that we provide solutions tailored to your unique needs, balancing technological possibilities with practical considerations."
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft"
                >
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                    <feature.icon className="w-8 h-8 text-primary-raspberry" />
                  </div>
                  <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                    {feature.title}
                  </h3>
                  <p className="font-opensans text-gray-600 dark:text-gray-300">
                    {feature.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-primary text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-10"></div>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative">
          <h2 className="font-outfit text-4xl font-bold mb-6">
            Ready to explore AI possibilities?
          </h2>
          <p className="font-opensans text-lg text-gray-100 mb-10">
            Partner with us to explore how human intelligence, supported by AI, can drive your organisation forward.
          </p>
          <Link to="/contact">
            <Button variant="secondary" size="lg" className="group">
              Get started
              <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
}