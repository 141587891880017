import { Link } from 'react-router-dom';
import { Mail, MapPin, Phone, Sparkles } from 'lucide-react';
import { motion } from 'framer-motion';
import { useState } from 'react';

export function Footer() {
  const [showEasterEgg, setShowEasterEgg] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleLogoClick = () => {
    setClickCount(prev => {
      const newCount = prev + 1;
      if (newCount === 3) {
        setShowEasterEgg(true);
        return 0;
      }
      return newCount;
    });
  };

  // Get sparkles style based on click count
  const getSparklesClass = () => {
    if (showEasterEgg) {
      return 'text-primary-raspberry animate-bounce';
    }
    
    switch (clickCount) {
      case 0:
        return 'text-primary-yellow opacity-70 hover:opacity-100';
      case 1:
        return 'text-primary-yellow opacity-85 hover:opacity-100 scale-110';
      case 2:
        return 'text-primary-raspberry opacity-100 scale-125';
      default:
        return 'text-primary-yellow opacity-70 hover:opacity-100';
    }
  };

  return (
    <footer className="bg-primary-gunmetal dark:bg-black text-white relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
      >
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h3 className="font-outfit text-lg font-semibold mb-4">About Us</h3>
            <p className="font-opensans text-gray-300">
              Transforming businesses through intelligent AI solutions. We help organisations leverage artificial intelligence to drive innovation and achieve measurable results.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <h3 className="font-outfit font-semibold text-lg mb-4">Quick links</h3>
            <ul className="space-y-2 font-opensans">
              <motion.li
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <Link to="/services" className="hover:text-primary-raspberry transition-colors">Services</Link>
              </motion.li>
              <motion.li
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <Link to="/about" className="hover:text-primary-raspberry transition-colors">About</Link>
              </motion.li>
              <motion.li
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <Link to="/contact" className="hover:text-primary-raspberry transition-colors">Contact</Link>
              </motion.li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <h3 className="font-outfit font-semibold text-lg mb-4">Contact</h3>
            <ul className="space-y-4 font-opensans">
              <motion.li 
                className="flex items-center"
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <Mail className="w-6 h-6 text-primary-raspberry mr-2" />
                <a href="mailto:hello@versantus.co.uk" className="hover:text-primary-raspberry transition-colors">hello@versantus.co.uk</a>
              </motion.li>
              <motion.li 
                className="flex items-center"
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <Phone className="w-6 h-6 text-primary-raspberry mr-2" />
                <a href="tel:+441865422112" className="hover:text-primary-raspberry transition-colors">01865 422112</a>
              </motion.li>
              <motion.li 
                className="flex items-center"
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <MapPin className="w-6 h-6 text-primary-raspberry mr-2" />
                <span>Oxford, United Kingdom</span>
              </motion.li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <h3 className="font-outfit font-semibold text-lg mb-4">Legal</h3>
            <ul className="space-y-2 font-opensans">
              <motion.li
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <Link to="/privacy" className="hover:text-primary-raspberry transition-colors">Privacy policy</Link>
              </motion.li>
              <motion.li
                whileHover={{ x: 5 }}
                transition={{ type: "spring", stiffness: 400 }}
              >
                <Link to="/credits" className="hover:text-primary-raspberry transition-colors">Image credits</Link>
              </motion.li>
            </ul>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="mt-12 pt-8 border-t border-primary-eggplant"
        >
          <p className="text-center font-opensans text-gray-300">
            Made with <span className="text-primary-raspberry">Love ❤️</span>, AI 🤖 and HI 🧠 by Versantus
            <Sparkles 
              className={`inline-block ml-2 cursor-pointer transition-all duration-300 ${getSparklesClass()}`}
              onClick={handleLogoClick}
            />
          </p>
          
          {showEasterEgg && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center mt-4 font-opensans text-primary-raspberry"
            >
              🎉 You found the easter egg! We love adding little surprises. 
              <a 
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" 
                target="_blank" 
                rel="noopener noreferrer"
                className="underline ml-1 hover:text-primary-raspberry/80"
              >
                Here's another one!
              </a>
            </motion.div>
          )}
        </motion.div>
      </motion.div>

      {/* Parallax Background Effect */}
      <div className="absolute inset-0 -z-10">
        <motion.div
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "linear"
          }}
          className="absolute inset-0 opacity-5"
          style={{
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgb(240, 20, 110) 1px, transparent 0)',
            backgroundSize: '40px 40px',
          }}
        />
      </div>
    </footer>
  );
}
