import { motion } from 'framer-motion';
import { Brain, Cpu, LineChart, Shield, Users, Zap, ArrowUpRight, MessageSquare } from 'lucide-react';
import { Button } from '../components/Button';
import { Link } from 'react-router-dom';

export function Services() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="relative bg-primary-eggplant text-white py-24 overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-20"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl"
          >
            <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
              AI consulting services
            </h1>
            <p className="mt-6 font-opensans text-lg text-gray-200">
              We help businesses harness the power of AI through practical, results-driven solutions. Our approach combines technical expertise with clear business understanding to deliver real value.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Main Services Section */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12">
            {[
              {
                icon: Brain,
                title: "AI Strategy Development",
                description: "We work with you to create a clear, practical AI roadmap that aligns with your business goals. This includes identifying opportunities, assessing feasibility, and planning implementation steps.",
                features: [
                  "Business opportunity analysis",
                  "Technology readiness assessment",
                  "ROI and impact evaluation",
                  "Implementation roadmap"
                ],
                link: "/services/ai-strategy"
              },
              {
                icon: Cpu,
                title: "AI Solution Implementation",
                description: "From proof-of-concept to full deployment, we help you implement AI solutions that deliver real business value. Our focus is on practical, measurable results.",
                features: [
                  "Custom AI model development",
                  "Integration with existing systems",
                  "Performance optimization",
                  "User training and documentation"
                ],
                link: "/services/implementation"
              },
              {
                icon: Shield,
                title: "AI Governance & Security",
                description: "We ensure your AI implementations are secure, ethical, and compliant with relevant regulations. Our approach balances innovation with responsible AI practices.",
                features: [
                  "Security assessment and implementation",
                  "Ethical AI guidelines",
                  "Compliance framework development",
                  "Risk management strategies"
                ],
                link: "/services/governance"
              },
              {
                icon: LineChart,
                title: "Performance Optimization",
                description: "We help you measure, monitor, and improve your AI solutions to ensure they continue delivering value over time.",
                features: [
                  "Performance monitoring setup",
                  "Optimization strategies",
                  "Regular health checks",
                  "Continuous improvement plans"
                ],
                link: "/services/performance"
              }
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft group hover:shadow-xl transition-all duration-300"
              >
                <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                  <service.icon className="w-8 h-8 text-primary-raspberry" />
                </div>
                <h3 className="font-outfit text-2xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                  {service.title}
                </h3>
                <p className="font-opensans text-gray-600 dark:text-gray-300 mb-6">
                  {service.description}
                </p>
                <ul className="space-y-3 mb-8">
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center text-gray-700 dark:text-gray-200">
                      <Zap className="w-5 h-5 text-primary-raspberry mr-3" />
                      <span className="font-opensans">{feature}</span>
                    </li>
                  ))}
                </ul>
                <Link to={service.link}>
                  <Button variant="outline" size="sm" className="group/btn w-full">
                    Learn more
                    <ArrowUpRight className="ml-2 w-4 h-4 transition-transform group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1" />
                  </Button>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-24 bg-white dark:bg-primary-eggplant relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-dark opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <h2 className="font-outfit text-4xl font-bold text-primary-gunmetal dark:text-white mb-6">
              Why choose Versantus?
            </h2>
            <p className="font-opensans text-lg text-gray-600 dark:text-gray-300">
              We combine technical expertise with practical business experience to deliver AI solutions that make a real difference.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Users,
                title: "Expert Team",
                description: "Our team brings together AI specialists, business analysts, and industry experts to deliver comprehensive solutions."
              },
              {
                icon: MessageSquare,
                title: "Clear Communication",
                description: "We explain complex concepts in plain English and keep you informed throughout the entire process."
              },
              {
                icon: LineChart,
                title: "Measurable Results",
                description: "We focus on delivering tangible business outcomes and ROI through practical AI implementation."
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="text-center p-6"
              >
                <div className="bg-primary-raspberry/10 rounded-full p-4 w-20 h-20 flex items-center justify-center mx-auto mb-6">
                  <feature.icon className="w-10 h-10 text-primary-raspberry" />
                </div>
                <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                  {feature.title}
                </h3>
                <p className="font-opensans text-gray-600 dark:text-gray-300">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-primary text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-10"></div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative"
        >
          <h2 className="font-outfit text-4xl font-bold mb-6">
            Ready to start your AI journey?
          </h2>
          <p className="font-opensans text-lg text-gray-100 mb-10">
            Let's discuss how AI can transform your business operations and drive growth.
          </p>
          <Link to="/contact">
            <Button variant="secondary" size="lg" className="group">
              Schedule a consultation
              <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </Button>
          </Link>
        </motion.div>
      </section>
    </div>
  );
}