import { motion } from 'framer-motion';
import { LineChart, ArrowUpRight, CheckCircle2, BarChart, TrendingUp, Target } from 'lucide-react';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import { AnimatedBackground } from '../../components/AnimatedBackground';

export function Performance() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="bg-primary-gunmetal text-white relative h-[600px]">
        <AnimatedBackground>
          <div className="py-24 h-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
              <div className="grid lg:grid-cols-2 gap-12 items-center h-full">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                    <LineChart className="w-8 h-8 text-primary-raspberry" />
                  </div>
                  <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
                    Performance optimization
                  </h1>
                  <p className="mt-6 font-opensans text-lg text-gray-200 max-w-xl">
                    Maximize the effectiveness of your AI solutions through continuous monitoring and optimization.
                  </p>
                  <div className="mt-10">
                    <Link to="/contact">
                      <Button variant="secondary" size="lg" className="group">
                        Optimize your AI systems
                        <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                      </Button>
                    </Link>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </AnimatedBackground>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Services Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {[
              {
                icon: BarChart,
                title: "Performance monitoring",
                description: "Continuous monitoring of AI system performance metrics and KPIs.",
                features: [
                  "Real-time monitoring",
                  "Performance metrics tracking",
                  "Automated alerts",
                  "System health checks"
                ]
              },
              {
                icon: TrendingUp,
                title: "Optimization strategy",
                description: "Data-driven strategies to improve AI system performance and efficiency.",
                features: [
                  "Performance analysis",
                  "Bottleneck identification",
                  "Resource optimization",
                  "Cost efficiency"
                ]
              },
              {
                icon: Target,
                title: "Continuous improvement",
                description: "Ongoing refinement and enhancement of AI systems for better results.",
                features: [
                  "Model retraining",
                  "System updates",
                  "Feature enhancement",
                  "Performance tuning"
                ]
              }
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft h-full"
              >
                <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                  <service.icon className="w-8 h-8 text-primary-raspberry" />
                </div>
                <h3 className="font-outfit text-2xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                  {service.title}
                </h3>
                <p className="font-opensans text-gray-600 dark:text-gray-300 mb-6">
                  {service.description}
                </p>
                <ul className="space-y-3">
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center space-x-3">
                      <CheckCircle2 className="w-5 h-5 text-primary-raspberry flex-shrink-0" />
                      <span className="font-opensans text-gray-600 dark:text-gray-300">{feature}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>

          {/* Process Section */}
          <div className="bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft">
            <h2 className="font-outfit text-3xl font-bold text-primary-gunmetal dark:text-white mb-8 text-center">
              Our optimization process
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  step: "1",
                  title: "Assessment",
                  description: "Comprehensive evaluation of current AI system performance and identification of optimization opportunities."
                },
                {
                  step: "2",
                  title: "Planning",
                  description: "Development of detailed optimization strategy based on assessment findings and business objectives."
                },
                {
                  step: "3",
                  title: "Implementation",
                  description: "Execution of optimization measures with minimal disruption to ongoing operations."
                },
                {
                  step: "4",
                  title: "Monitoring",
                  description: "Continuous monitoring and adjustment of optimizations to ensure sustained performance improvements."
                }
              ].map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="relative"
                >
                  <div className="bg-primary-raspberry/10 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                    <span className="font-outfit font-semibold text-primary-raspberry">{step.step}</span>
                  </div>
                  <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-3">
                    {step.title}
                  </h3>
                  <p className="font-opensans text-gray-600 dark:text-gray-300">
                    {step.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-primary text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-10"></div>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative">
          <h2 className="font-outfit text-4xl font-bold mb-6">
            Ready to optimize your AI performance?
          </h2>
          <p className="font-opensans text-lg text-gray-100 mb-10">
            Let's work together to maximize the effectiveness of your AI systems.
          </p>
          <Link to="/contact">
            <Button variant="secondary" size="lg" className="group">
              Get started
              <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
}