import { Users, Award, Sparkles, ArrowUpRight } from 'lucide-react';
import { Button } from '../components/Button';
import { Link } from 'react-router-dom';

export function About() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="relative bg-primary-gunmetal text-white py-24 overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-20"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="max-w-3xl">
            <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
              About Versantus
            </h1>
            <p className="mt-6 font-opensans text-lg text-gray-200">
              We're a team of passionate technologists, strategists, and innovators dedicated to transforming businesses through intelligent solutions.
            </p>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-dark opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="grid md:grid-cols-3 gap-12">
            {[
              {
                icon: Users,
                title: "People First",
                description: "We believe in building lasting relationships with our clients and our team, fostering an environment of collaboration and growth."
              },
              {
                icon: Award,
                title: "Excellence",
                description: "We strive for excellence in everything we do, delivering solutions that exceed expectations and drive real business value."
              },
              {
                icon: Sparkles,
                title: "Innovation",
                description: "We're constantly pushing boundaries, exploring new technologies, and finding innovative ways to solve complex challenges."
              }
            ].map((value, index) => (
              <div key={index} className="relative bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft">
                <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                  <value.icon className="w-8 h-8 text-primary-raspberry" />
                </div>
                <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                  {value.title}
                </h3>
                <p className="font-opensans text-primary-gunmetal dark:text-gray-200">
                  {value.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Story Section */}
      <section className="py-24 bg-white dark:bg-primary-eggplant relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-dark opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="font-outfit text-4xl font-bold text-primary-gunmetal dark:text-white mb-6">
                Our Story
              </h2>
              <div className="space-y-6 font-opensans text-primary-gunmetal dark:text-gray-200">
                <p>
                  Since 2009, Versantus has been at the forefront of digital innovation, helping businesses navigate and thrive in an ever-evolving technological landscape.
                </p>
                <p>
                  Based in Oxford, we've grown from a small web development agency into a full-service digital transformation partner, specializing in artificial intelligence solutions that drive real business value.
                </p>
                <p>
                  Our team combines deep technical expertise with strategic business acumen, allowing us to deliver solutions that not only solve immediate challenges but also position our clients for future success.
                </p>
              </div>
              <div className="mt-8">
                <Link to="/contact">
                  <Button variant="primary" size="lg" className="group">
                    Work with us
                    <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                  </Button>
                </Link>
              </div>
            </div>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-primary opacity-20 rounded-2xl"></div>
              <img 
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&w=1200&q=80" 
                alt="Versantus team collaboration" 
                className="rounded-2xl shadow-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}