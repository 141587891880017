import React from 'react';

export function Credits() {
  return (
    <div className="pt-28 pb-20 bg-white dark:bg-primary-gunmetal">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="font-outfit text-4xl font-bold text-primary-gunmetal dark:text-white mb-12">
          Image Credits
        </h1>
        
        <div className="space-y-12">
          <section>
            <h2 className="font-outfit text-2xl font-bold text-primary-gunmetal dark:text-white mb-6">
              Case Studies
            </h2>
            <ul className="space-y-4 font-opensans text-gray-600 dark:text-gray-300">
              <li>
                <strong>Badminton Horse Trials:</strong> Photo by Elisa Pitkänen on Unsplash (<a href="https://unsplash.com/photos/unknown-person-riding-on-white-horse-9Xs9s9Pe274?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash" className="text-primary-raspberry hover:underline" target="_blank" rel="noopener noreferrer">Link</a>)
              </li>
              <li>
                <strong>Audley Villages:</strong> Photo by Keith Tanner on Unsplash (<a href="https://unsplash.com/photos/a-woman-and-a-man-in-a-living-room-iDBf7a9pn7s?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash" className="text-primary-raspberry hover:underline" target="_blank" rel="noopener noreferrer">Link</a>)
              </li>
              <li>
                <strong>Guides for Brides:</strong> Photo by Álvaro CvG on Unsplash (<a href="https://unsplash.com/photos/photo-of-a-man-and-woman-newly-wedding-holding-a-balloons-mW8IZdX7n8E?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash" className="text-primary-raspberry hover:underline" target="_blank" rel="noopener noreferrer">Link</a>)
              </li>
              <li>
                <strong>Custom GPTs:</strong> Photo by Mariia Shalabaieva on Unsplash (<a href="https://unsplash.com/photos/mariia-shalabaieva" className="text-primary-raspberry hover:underline" target="_blank" rel="noopener noreferrer">Link</a>)
              </li>
              <li>
                <strong>Oxford:</strong> Photo by Ben Seymour on Unsplash (<a href="https://unsplash.com/photos/beige-concrete-building-under-blue-sky-during-daytime-nqBUTBsDQNM?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash" className="text-primary-raspberry hover:underline" target="_blank" rel="noopener noreferrer">Link</a>)
              </li>
              <li>
                <strong>MHR Development:</strong> Photo by Brooke Cagle on Unsplash (<a href="https://unsplash.com/photos/three-people-sitting-in-front-of-table-laughing-together-g1Kr4Ozfoac?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash" className="text-primary-raspberry hover:underline" target="_blank" rel="noopener noreferrer">Link</a>)
              </li>
            </ul>
          </section>
          
          <section>
            <h2 className="font-outfit text-2xl font-bold text-primary-gunmetal dark:text-white mb-6">
              Other Imagery
            </h2>
            <p className="font-opensans text-gray-600 dark:text-gray-300 mb-4">
              Additional images used on this site are either owned by Versantus AI, licensed through appropriate channels, or sourced from open license platforms like Unsplash.
            </p>
            <p className="font-opensans text-gray-600 dark:text-gray-300">
              If you have any questions about image usage or licensing, please <a href="/contact" className="text-primary-raspberry hover:underline">contact us</a>.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Credits;