import { motion } from 'framer-motion';
import { AnimatedBackground } from '../components/AnimatedBackground';

export function Privacy() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="bg-primary-gunmetal text-white">
        <AnimatedBackground>
          <div className="py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <h1 className="font-outfit text-4xl md:text-5xl font-bold leading-tight mb-6">
                  Privacy Policy
                </h1>
                <p className="font-opensans text-lg text-gray-200 max-w-3xl">
                  At Versantus, we take your privacy seriously. This policy explains how we collect, use, and protect your personal information.
                </p>
              </motion.div>
            </div>
          </div>
        </AnimatedBackground>
      </section>

      {/* Content Section */}
      <section className="py-16 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="prose dark:prose-invert prose-lg max-w-none">
            <h2 className="font-outfit text-2xl font-semibold mb-4">Introduction</h2>
            <p>This privacy policy sets out how Versantus uses and protects any information that you provide when you use this website or our services.</p>
            
            <h2 className="font-outfit text-2xl font-semibold mt-8 mb-4">Information We Collect</h2>
            <p>We may collect the following information:</p>
            <ul className="list-disc pl-6 mb-6">
              <li>Name and job title</li>
              <li>Contact information including email address</li>
              <li>Demographic information such as postcode, preferences and interests</li>
              <li>Other information relevant to customer surveys and/or offers</li>
            </ul>

            <h2 className="font-outfit text-2xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
            <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
            <ul className="list-disc pl-6 mb-6">
              <li>Internal record keeping</li>
              <li>Improving our products and services</li>
              <li>Sending promotional emails about new products, special offers or other information which we think you may find interesting</li>
              <li>From time to time, we may also use your information to contact you for market research purposes</li>
            </ul>

            <h2 className="font-outfit text-2xl font-semibold mt-8 mb-4">Security</h2>
            <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>

            <h2 className="font-outfit text-2xl font-semibold mt-8 mb-4">Cookies</h2>
            <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site.</p>
            <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs.</p>

            <h2 className="font-outfit text-2xl font-semibold mt-8 mb-4">Links to Other Websites</h2>
            <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites.</p>

            <h2 className="font-outfit text-2xl font-semibold mt-8 mb-4">Controlling Your Personal Information</h2>
            <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
            <ul className="list-disc pl-6 mb-6">
              <li>Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
              <li>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us</li>
            </ul>

            <h2 className="font-outfit text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
            <p>If you have any questions about this privacy policy or how we handle your personal information, please contact us at:</p>
            <p className="mt-4">
              Email: <a href="mailto:hello@versantus.co.uk" className="text-primary-raspberry hover:text-primary-raspberry/80">hello@versantus.co.uk</a><br />
              Phone: <a href="tel:+441865422112" className="text-primary-raspberry hover:text-primary-raspberry/80">01865 422112</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}