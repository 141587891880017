import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import { motion } from 'framer-motion';
import { ArrowLeft, ArrowUp, ArrowRight, ArrowDown } from 'lucide-react';

interface Position {
  x: number;
  y: number;
}

const GRID_SIZE = 20;
const CELL_SIZE = 20;
const INITIAL_SNAKE: Position[] = [{ x: 10, y: 10 }];
const INITIAL_DIRECTION = { x: 1, y: 0 };
const GAME_SPEED = 150;

export function NotFound() {
  const [snake, setSnake] = useState<Position[]>(INITIAL_SNAKE);
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [food, setFood] = useState<Position>({ x: 15, y: 10 });
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const generateFood = useCallback(() => {
    let newFood: Position;
    do {
      newFood = {
        x: Math.floor(Math.random() * GRID_SIZE),
        y: Math.floor(Math.random() * GRID_SIZE)
      };
    } while (snake.some(segment => segment.x === newFood.x && segment.y === newFood.y));
    setFood(newFood);
  }, [snake]);

  const resetGame = () => {
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
    setGameOver(false);
    setScore(0);
    generateFood();
    setIsPlaying(true);
  };

  const moveSnake = useCallback(() => {
    if (gameOver) return;

    setSnake(currentSnake => {
      const head = currentSnake[0];
      const newHead = {
        x: head.x + direction.x,
        y: head.y + direction.y
      };

      // Check for collisions with walls or self
      if (
        newHead.x < 0 ||
        newHead.x >= GRID_SIZE ||
        newHead.y < 0 ||
        newHead.y >= GRID_SIZE ||
        currentSnake.some(segment => segment.x === newHead.x && segment.y === newHead.y)
      ) {
        setGameOver(true);
        setHighScore(current => Math.max(current, score));
        return currentSnake;
      }

      const newSnake = [newHead, ...currentSnake];

      // Check if snake ate food
      if (newHead.x === food.x && newHead.y === food.y) {
        setScore(s => s + 1);
        generateFood();
      } else {
        newSnake.pop();
      }

      return newSnake;
    });
  }, [direction, food, gameOver, generateFood, score]);

  useEffect(() => {
    if (!isPlaying) return;

    const gameInterval = setInterval(moveSnake, GAME_SPEED);
    return () => clearInterval(gameInterval);
  }, [isPlaying, moveSnake]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!isPlaying) return;

      e.preventDefault(); // Prevent page scrolling

      switch (e.key) {
        case 'ArrowUp':
          if (direction.y !== 1) setDirection({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          if (direction.y !== -1) setDirection({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          if (direction.x !== 1) setDirection({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          if (direction.x !== -1) setDirection({ x: 1, y: 0 });
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [direction, isPlaying]);

  return (
    <div className="min-h-screen pt-20 bg-white dark:bg-primary-gunmetal">
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-6xl font-bold text-primary-gunmetal dark:text-white mb-4"
        >
          404
        </motion.h1>
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-xl text-gray-600 dark:text-gray-300 mb-8"
        >
          Oops! Looks like this page got lost in the matrix.
        </motion.p>

        <motion.div 
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.4 }}
          className="mb-8"
        >
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-4">
            While you're here, why not play a quick game of Snake?
          </p>
          <div className="flex flex-col items-center gap-4">
            <div 
              className="relative bg-gray-100 dark:bg-primary-eggplant rounded-lg"
              style={{ 
                width: GRID_SIZE * CELL_SIZE,
                height: GRID_SIZE * CELL_SIZE
              }}
            >
              {/* Snake */}
              {snake.map((segment, i) => (
                <div
                  key={`${segment.x}-${segment.y}-${i}`}
                  className="absolute bg-primary-raspberry transition-all duration-150"
                  style={{
                    width: CELL_SIZE - 2,
                    height: CELL_SIZE - 2,
                    left: segment.x * CELL_SIZE + 1,
                    top: segment.y * CELL_SIZE + 1,
                    borderRadius: i === 0 ? '4px' : '2px'
                  }}
                />
              ))}

              {/* Food */}
              <div
                className="absolute bg-primary-amethyst transition-all duration-150"
                style={{
                  width: CELL_SIZE - 2,
                  height: CELL_SIZE - 2,
                  left: food.x * CELL_SIZE + 1,
                  top: food.y * CELL_SIZE + 1,
                  borderRadius: '50%'
                }}
              />

              {/* Game Over Overlay */}
              {gameOver && (
                <div className="absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg">
                  <div className="text-white text-xl font-bold">Game Over!</div>
                </div>
              )}
            </div>

            <div className="flex justify-center gap-4 text-lg">
              <p className="text-gray-600 dark:text-gray-300">Score: {score}</p>
              <p className="text-gray-600 dark:text-gray-300">High Score: {highScore}</p>
            </div>

            <div className="space-y-4">
              {!isPlaying ? (
                <Button variant="primary" onClick={resetGame}>Start Game</Button>
              ) : gameOver ? (
                <Button variant="primary" onClick={resetGame}>Play Again</Button>
              ) : (
                <div className="grid grid-cols-3 gap-2 w-[120px]">
                  <div />
                  <button
                    onClick={() => setDirection({ x: 0, y: -1 })}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-white dark:bg-primary-gunmetal border-2 border-primary-raspberry hover:bg-primary-raspberry/5 transition-colors"
                  >
                    <ArrowUp className="w-6 h-6 text-primary-raspberry" />
                  </button>
                  <div />
                  <button
                    onClick={() => setDirection({ x: -1, y: 0 })}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-white dark:bg-primary-gunmetal border-2 border-primary-raspberry hover:bg-primary-raspberry/5 transition-colors"
                  >
                    <ArrowLeft className="w-6 h-6 text-primary-raspberry" />
                  </button>
                  <div />
                  <button
                    onClick={() => setDirection({ x: 1, y: 0 })}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-white dark:bg-primary-gunmetal border-2 border-primary-raspberry hover:bg-primary-raspberry/5 transition-colors"
                  >
                    <ArrowRight className="w-6 h-6 text-primary-raspberry" />
                  </button>
                  <div />
                  <button
                    onClick={() => setDirection({ x: 0, y: 1 })}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-white dark:bg-primary-gunmetal border-2 border-primary-raspberry hover:bg-primary-raspberry/5 transition-colors"
                  >
                    <ArrowDown className="w-6 h-6 text-primary-raspberry" />
                  </button>
                  <div />
                </div>
              )}
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          <Link to="/">
            <Button variant="outline">
              Return to Home
            </Button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
}