import { useTheme } from './ThemeProvider';
import { cn } from '../lib/utils';

interface LogoProps {
  className?: string;
}

export function Logo({ className }: LogoProps) {
  const { theme } = useTheme();

  return (
    <img 
      src={theme === 'dark' ? '/logos/versantus_white_land.png' : '/logos/versantus_full_col_land.png'}
      alt="Versantus"
      className={cn("h-8 w-auto transition-opacity", className)}
    />
  );
}