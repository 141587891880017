import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeProvider';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Services } from './pages/Services';
import { Privacy } from './pages/Privacy';
import { Credits } from './pages/Credits';
import { AIStrategy } from './pages/services/AIStrategy';
import { Implementation } from './pages/services/Implementation';
import { Governance } from './pages/services/Governance';
import { Performance } from './pages/services/Performance';
import { CaseStudies } from './pages/case-studies/CaseStudies';
import { CaseStudy } from './pages/case-studies/CaseStudy';
import { NotFound } from './pages/NotFound';
import { Login } from './pages/admin/Login';
import { Dashboard } from './pages/admin/Dashboard';
import { HI } from './pages/HI';
import { Toaster } from 'sonner';
import { useEffect } from 'react';
import { updateSEO } from './lib/seo';
import { ScrollToTop } from './components/ScrollToTop';
import { GTMProvider, pushToDataLayer } from './lib/gtm';

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    // Update SEO metadata based on current route
    const seoData = {
      '/': {
        title: 'Versantus AI | AI consulting services',
        description: 'Expert AI consulting services helping businesses leverage artificial intelligence for growth and innovation.'
      },
      '/about': {
        title: 'About us | Versantus AI',
        description: 'Learn about our team of AI experts and our mission to transform businesses through intelligent solutions.'
      },
      '/contact': {
        title: 'Contact us | Versantus AI',
        description: 'Get in touch with our AI consulting team to discuss your business needs and transformation goals.'
      },
      '/services': {
        title: 'Our services | Versantus AI',
        description: 'Comprehensive AI consulting services including strategy, implementation, governance, and optimization.'
      },
      '/case-studies': {
        title: 'Case Studies | Versantus AI',
        description: 'Discover how we help businesses achieve exceptional results through AI implementation.'
      },
      '/hi': {
        title: 'Say HI to AI | Versantus AI',
        description: 'Learn how we combine Human Intelligence with Artificial Intelligence to drive innovation and efficiency.'
      },
      '/services/ai-strategy': {
        title: 'AI strategy services | Versantus AI',
        description: 'Strategic AI consulting to help businesses develop and implement effective AI roadmaps.'
      },
      '/services/implementation': {
        title: 'AI implementation services | Versantus AI',
        description: 'Expert AI implementation services to integrate AI solutions into your existing infrastructure.'
      },
      '/services/governance': {
        title: 'AI governance services | Versantus AI',
        description: 'Ensure responsible AI deployment with our comprehensive governance and security services.'
      },
      '/services/performance': {
        title: 'AI performance optimization | Versantus AI',
        description: 'Optimize your AI solutions for maximum efficiency and business impact.'
      },
      '/privacy': {
        title: 'Privacy policy | Versantus AI',
        description: 'Learn about how we protect your privacy and handle your data.'
      },
      '/credits': {
        title: 'Image Credits | Versantus AI',
        description: 'Attribution and credits for images used throughout the Versantus AI website.'
      }
    };

    const currentPath = location.pathname as keyof typeof seoData;
    if (seoData[currentPath]) {
      updateSEO(seoData[currentPath]);
      // Push page view to GTM
      pushToDataLayer({
        event: 'pageview',
        page: {
          path: location.pathname,
          title: seoData[currentPath].title,
          type: 'virtual'
        }
      });
    }
  }, [location]);

  return (
    <div className="min-h-screen flex flex-col bg-secondary-white dark:bg-primary-gunmetal transition-colors">
      <Navbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/hi" element={<HI />} />
          <Route path="/services/ai-strategy" element={<AIStrategy />} />
          <Route path="/services/implementation" element={<Implementation />} />
          <Route path="/services/governance" element={<Governance />} />
          <Route path="/services/performance" element={<Performance />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-studies/:slug" element={<CaseStudy />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
      <Toaster position="top-right" />
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <GTMProvider gtmId="GTM-PLXG56FD">
        <Router>
          <ScrollToTop />
          <AppContent />
        </Router>
      </GTMProvider>
    </ThemeProvider>
  );
}

export default App;
