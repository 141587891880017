import { motion } from 'framer-motion';
import { Cpu, ArrowUpRight, CheckCircle2, Code2, Database, Network, Bot } from 'lucide-react';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import { AnimatedBackground } from '../../components/AnimatedBackground';

export function Implementation() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="bg-primary-gunmetal text-white relative h-[600px]">
        <AnimatedBackground>
          <div className="py-24 h-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
              <div className="grid lg:grid-cols-2 gap-12 items-center h-full">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                    <Cpu className="w-8 h-8 text-primary-raspberry" />
                  </div>
                  <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
                    AI implementation & integration
                  </h1>
                  <p className="mt-6 font-opensans text-lg text-gray-200 max-w-xl">
                    Expert implementation and seamless integration of AI solutions into your existing infrastructure.
                  </p>
                  <div className="mt-10">
                    <Link to="/contact">
                      <Button variant="secondary" size="lg" className="group">
                        Start your implementation
                        <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                      </Button>
                    </Link>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </AnimatedBackground>
      </section>

      {/* Services Grid */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                icon: Code2,
                title: "Custom AI development",
                description: "Tailored AI solutions built to address your specific business challenges and requirements.",
                features: [
                  "Machine learning models",
                  "Natural language processing",
                  "Computer vision systems",
                  "Predictive analytics"
                ]
              },
              {
                icon: Database,
                title: "Data integration",
                description: "Seamless integration of AI systems with your existing data infrastructure and workflows.",
                features: [
                  "Data pipeline setup",
                  "ETL processes",
                  "Real-time processing",
                  "Data warehousing"
                ]
              },
              {
                icon: Network,
                title: "System integration",
                description: "Connect AI solutions with your current business systems and applications.",
                features: [
                  "API development",
                  "Middleware integration",
                  "Legacy system compatibility",
                  "Cloud deployment"
                ]
              },
              {
                icon: Bot,
                title: "Automation solutions",
                description: "Implement AI-powered automation to streamline operations and reduce manual work.",
                features: [
                  "Process automation",
                  "Workflow optimization",
                  "Intelligent routing",
                  "Decision support systems"
                ]
              }
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft"
              >
                <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                  <service.icon className="w-8 h-8 text-primary-raspberry" />
                </div>
                <h3 className="font-outfit text-2xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                  {service.title}
                </h3>
                <p className="font-opensans text-gray-600 dark:text-gray-300 mb-6">
                  {service.description}
                </p>
                <ul className="space-y-3">
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center space-x-3">
                      <CheckCircle2 className="w-5 h-5 text-primary-raspberry flex-shrink-0" />
                      <span className="font-opensans text-gray-600 dark:text-gray-300">{feature}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-primary text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-10"></div>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative">
          <h2 className="font-outfit text-4xl font-bold mb-6">
            Ready to implement AI in your business?
          </h2>
          <p className="font-opensans text-lg text-gray-100 mb-10">
            Let's discuss your implementation needs and create a solution that drives real results.
          </p>
          <Link to="/contact">
            <Button variant="secondary" size="lg" className="group">
              Schedule a consultation
              <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
}