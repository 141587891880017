import { createContext, useContext, useEffect } from 'react';

interface GTMContextType {
  gtmId: string;
}

const GTMContext = createContext<GTMContextType | null>(null);

interface GTMProviderProps {
  children: React.ReactNode;
  gtmId: string;
}

export function GTMProvider({ children, gtmId }: GTMProviderProps) {
  useEffect(() => {
    // Initialize GTM dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    // Load GTM script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(script);

    // Add GTM noscript iframe
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [gtmId]);

  return (
    <GTMContext.Provider value={{ gtmId }}>
      {children}
    </GTMContext.Provider>
  );
}

function useGTM() {
  const context = useContext(GTMContext);
  if (!context) {
    throw new Error('useGTM must be used within a GTMProvider');
  }
  return context;
}

// Helper function to push events to dataLayer
export function pushToDataLayer(event: Record<string, any>) {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
}