import { ArrowRight, Brain, BarChart as ChartBar, Shield, ArrowUpRight } from 'lucide-react';
import { Button } from '../components/Button';
import { TypewriterText } from '../components/TypewriterText';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { AnimatedBackground } from '../components/AnimatedBackground';

export function Home() {
  const navigate = useNavigate();

  const featuredCaseStudies = [
    {
      title: "Drupal AI tools for Audley",
      description: "Enhancing content management and user experience through AI-powered tools integrated into Audley Villages' Drupal-based website.",
      image: "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&w=1200&q=80",
      industry: "Retirement living",
      slug: "audley-villages"
    },
    {
      title: "Marketing automation for Guides for Brides",
      description: "Streamlining content workflows and improving SEO performance through intelligent automation for the UK's leading wedding platform.",
      image: "https://images.unsplash.com/photo-1606800052052-a08af7148866?auto=format&fit=crop&w=1200&q=80",
      industry: "Wedding industry",
      slug: "guides-for-brides"
    },
    {
      title: "Custom GPTs for content generation",
      description: "Developing specialized GPT models for automated content creation, enhancing productivity and maintaining brand consistency.",
      image: "https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&w=1200&q=80",
      industry: "AI & Content",
      slug: "custom-gpts"
    }
  ];

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="bg-primary-gunmetal text-white min-h-[80vh] relative">
        <AnimatedBackground>
          <div className="py-24 h-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
              <div className="grid lg:grid-cols-2 gap-12 items-center h-full">
                <div>
                  <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
                    Transforming business through
                    <span className="text-primary-raspberry block mt-2">
                      <TypewriterText />
                    </span>
                  </h1>
                  <p className="mt-6 font-opensans text-lg text-gray-200 max-w-xl">
                    Partner with Versantus AI to unlock the full potential of artificial intelligence. 
                    We deliver strategic consulting and implementation services that drive measurable business outcomes.
                  </p>
                  <div className="mt-10 flex flex-wrap gap-4">
                    <Button variant="secondary" size="lg" className="group" onClick={() => navigate('/contact')}>
                      Start your AI journey
                      <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                    </Button>
                    <Link to="/services">
                      <Button variant="outline" size="lg" className="border-white text-white hover:bg-white hover:text-primary-gunmetal">
                        View our services
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimatedBackground>
      </section>

      {/* Services Section */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-dark opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="font-outfit text-4xl font-bold text-primary-gunmetal dark:text-white">
              Comprehensive AI solutions
            </h2>
            <p className="mt-6 font-opensans text-lg text-primary-gunmetal dark:text-gray-200">
              We help businesses harness the power of artificial intelligence through strategic consulting, 
              implementation and ongoing support services.
            </p>
          </div>

          <div className="mt-20 grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Brain,
                title: "AI strategy and consulting",
                description: "Develop a comprehensive AI roadmap aligned with your business objectives and market opportunities.",
                link: "/services/ai-strategy"
              },
              {
                icon: ChartBar,
                title: "Implementation and integration",
                description: "Seamlessly integrate AI solutions into your existing infrastructure with our expert technical team.",
                link: "/services/implementation"
              },
              {
                icon: Shield,
                title: "AI governance and security",
                description: "Ensure responsible AI deployment with robust security measures and governance frameworks.",
                link: "/services/governance"
              }
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="group relative bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft hover:shadow-xl transition-all duration-300"
              >
                <div className="absolute inset-0 bg-gradient-primary opacity-0 group-hover:opacity-5 rounded-xl transition-opacity"></div>
                <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                  <service.icon className="w-8 h-8 text-primary-raspberry" />
                </div>
                <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-4">{service.title}</h3>
                <p className="font-opensans text-primary-gunmetal dark:text-gray-200 mb-6">{service.description}</p>
                <Link to={service.link}>
                  <Button variant="outline" size="sm" className="group/btn w-full">
                    Learn more 
                    <ArrowRight className="ml-2 w-4 h-4 transition-transform group-hover/btn:translate-x-1" />
                  </Button>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Case Studies Section */}
      <section className="py-24 bg-white dark:bg-primary-eggplant relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-dark opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="font-outfit text-4xl font-bold text-primary-gunmetal dark:text-white">Success stories</h2>
            <p className="mt-6 font-opensans text-lg text-primary-gunmetal dark:text-gray-200">
              Discover how we've helped leading businesses achieve exceptional results through AI implementation.
            </p>
          </div>

          <div className="mt-20 grid md:grid-cols-3 gap-8">
            {featuredCaseStudies.map((study, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="group bg-white dark:bg-primary-gunmetal rounded-xl overflow-hidden shadow-soft hover:shadow-xl transition-all duration-300"
              >
                <div className="relative h-48 overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-primary opacity-10 group-hover:opacity-20 transition-opacity"></div>
                  <img 
                    src={study.image} 
                    alt={study.title} 
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
                  />
                </div>
                <div className="p-6">
                  <div className="inline-block px-4 py-1 rounded-full bg-primary-raspberry/10 text-primary-raspberry font-outfit text-sm font-medium mb-4">
                    {study.industry}
                  </div>
                  <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                    {study.title}
                  </h3>
                  <p className="font-opensans text-primary-gunmetal dark:text-gray-200 mb-6">
                    {study.description}
                  </p>
                  <Link to={`/case-studies/${study.slug}`}>
                    <Button variant="primary" size="sm" className="group/btn w-full">
                      Read case study
                      <ArrowUpRight className="ml-2 w-4 h-4 transition-transform group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1" />
                    </Button>
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
          
          <div className="mt-12 text-center">
            <Link to="/case-studies">
              <Button variant="outline" size="lg" className="group">
                View all case studies
                <ArrowRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1" />
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-primary text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-10"></div>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative">
          <h2 className="font-outfit text-4xl font-bold">Ready to transform your business?</h2>
          <p className="mt-6 font-opensans text-lg text-gray-100 mb-10">
            Let's discuss how we can help you leverage AI to drive innovation and achieve measurable results.
          </p>
          <Link to="/contact">
            <Button variant="secondary" size="lg" className="group">
              Schedule a consultation
              <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
}