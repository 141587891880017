import { useState } from 'react';
import { Mail, MapPin, Phone } from 'lucide-react';
import { Button } from '../components/Button';
import { toast } from 'sonner';

export function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Get form element
      const form = e.target as HTMLFormElement;
      const formData = new FormData(form);
      
      // Submit the form data to Netlify
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData as any).toString()
      });
      
      // Show thank you message
      setFormSubmitted(true);
      form.reset();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="relative bg-primary-gunmetal text-white py-24 overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-20"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="max-w-3xl">
            <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
              Let's start a conversation
            </h1>
            <p className="mt-6 font-opensans text-lg text-gray-200">
              Ready to explore how AI can transform your business? Get in touch with our team of experts.
            </p>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-dark opacity-5"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="grid lg:grid-cols-2 gap-12">
            {/* Contact Information */}
            <div>
              <h2 className="font-outfit text-3xl font-bold text-primary-gunmetal dark:text-white mb-8">
                Get in touch
              </h2>
              <div className="space-y-8">
                <div className="flex items-start">
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 mr-6">
                    <Mail className="w-6 h-6 text-primary-raspberry" />
                  </div>
                  <div>
                    <h3 className="font-outfit text-lg font-semibold text-primary-gunmetal dark:text-white mb-2">
                      Email us
                    </h3>
                    <a 
                      href="mailto:hello@versantus.co.uk" 
                      className="font-opensans text-primary-gunmetal dark:text-gray-200 hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
                    >
                      hello@versantus.co.uk
                    </a>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 mr-6">
                    <Phone className="w-6 h-6 text-primary-raspberry" />
                  </div>
                  <div>
                    <h3 className="font-outfit text-lg font-semibold text-primary-gunmetal dark:text-white mb-2">
                      Call us
                    </h3>
                    <a 
                      href="tel:+441865422112" 
                      className="font-opensans text-primary-gunmetal dark:text-gray-200 hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
                    >
                      01865 422112
                    </a>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 mr-6">
                    <MapPin className="w-6 h-6 text-primary-raspberry" />
                  </div>
                  <div>
                    <h3 className="font-outfit text-lg font-semibold text-primary-gunmetal dark:text-white mb-2">
                      Visit us
                    </h3>
                    <p className="font-opensans text-primary-gunmetal dark:text-gray-200">
                      Oxford, United Kingdom
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form or Thank You Message */}
            <div className="bg-white rounded-xl p-8 shadow-lg border border-transparent dark:border-primary-raspberry">
              {formSubmitted ? (
                // Thank You Message
                <div className="text-center">
                  <div className="mb-8">
                    <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-8 w-8 text-green-600" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M5 13l4 4L19 7" 
                        />
                      </svg>
                    </div>
                  </div>
                  
                  <h2 className="font-outfit text-2xl font-bold text-primary-gunmetal mb-4">
                    Thank You!
                  </h2>
                  
                  <p className="text-gray-600 mb-6">
                    Your message has been sent successfully. We'll get back to you as soon as possible.
                  </p>
                  
                  <Button 
                    type="button" 
                    variant="primary" 
                    className="w-full"
                    onClick={() => setFormSubmitted(false)}
                  >
                    Send Another Message
                  </Button>
                </div>
              ) : (
                // Contact Form
                <form 
                  name="contact" 
                  method="POST" 
                  onSubmit={handleSubmit}
                  className="space-y-6"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  {/* Required for Netlify forms */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="hidden">
                    <input name="bot-field" />
                  </div>
                  
                  <div>
                    <label 
                      htmlFor="name" 
                      className="block font-outfit text-sm font-medium text-primary-gunmetal dark:text-primary-gunmetal mb-2"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      required
                      className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 dark:border-primary-raspberry/50 bg-white text-primary-gunmetal dark:text-primary-gunmetal focus:outline-none focus:ring-2 focus:ring-primary-raspberry shadow-sm"
                    />
                  </div>

                  <div>
                    <label 
                      htmlFor="email" 
                      className="block font-outfit text-sm font-medium text-primary-gunmetal dark:text-primary-gunmetal mb-2"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 dark:border-primary-raspberry/50 bg-white text-primary-gunmetal dark:text-primary-gunmetal focus:outline-none focus:ring-2 focus:ring-primary-raspberry shadow-sm"
                    />
                  </div>

                  <div>
                    <label 
                      htmlFor="phone" 
                      className="block font-outfit text-sm font-medium text-primary-gunmetal dark:text-primary-gunmetal mb-2"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 dark:border-primary-raspberry/50 bg-white text-primary-gunmetal dark:text-primary-gunmetal focus:outline-none focus:ring-2 focus:ring-primary-raspberry shadow-sm"
                    />
                  </div>

                  <div>
                    <label 
                      htmlFor="company" 
                      className="block font-outfit text-sm font-medium text-primary-gunmetal dark:text-primary-gunmetal mb-2"
                    >
                      Company
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 dark:border-primary-raspberry/50 bg-white text-primary-gunmetal dark:text-primary-gunmetal focus:outline-none focus:ring-2 focus:ring-primary-raspberry shadow-sm"
                    />
                  </div>

                  <div>
                    <label 
                      htmlFor="message" 
                      className="block font-outfit text-sm font-medium text-primary-gunmetal dark:text-primary-gunmetal mb-2"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      required
                      rows={4}
                      className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 dark:border-primary-raspberry/50 bg-white text-primary-gunmetal dark:text-primary-gunmetal focus:outline-none focus:ring-2 focus:ring-primary-raspberry shadow-sm resize-none"
                    />
                  </div>

                  <div className="bg-primary-raspberry/10 dark:bg-white p-6 rounded-lg border border-transparent dark:border-primary-raspberry">
                    <div className="flex items-start mb-4">
                      <input
                        id="aiConsultation"
                        type="checkbox"
                        name="aiConsultation"
                        className="mt-1 h-5 w-5 rounded border-2 border-gray-300 dark:border-primary-raspberry text-primary-raspberry focus:ring-primary-raspberry shadow-sm"
                      />
                      <label
                        htmlFor="aiConsultation"
                        className="ml-3 block font-outfit font-medium text-primary-gunmetal dark:text-primary-gunmetal"
                      >
                        Book an AI consultation
                      </label>
                    </div>
                    <div className="ml-7">
                      <p className="font-opensans text-sm text-primary-gunmetal dark:text-primary-gunmetal mb-2">
                        The free consultation includes...
                      </p>
                      <ul className="list-disc pl-5 font-opensans text-sm text-primary-gunmetal dark:text-primary-gunmetal space-y-1">
                        <li>discuss your organisational goals</li>
                        <li>explore how AI could benefit your business</li>
                        <li>interactive demo of the latest tools</li>
                        <li>real world take-aways</li>
                      </ul>
                    </div>
                  </div>

                  <Button 
                    type="submit" 
                    variant="primary" 
                    className="w-full"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Sending...' : 'Send message'}
                  </Button>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
