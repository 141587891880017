import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Home, Brain } from 'lucide-react';
import { Button } from './Button';
import { ThemeToggle } from './ThemeToggle';
import { Logo } from './Logo';

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  
  // Close menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isMenuOpen && 
        menuRef.current && 
        buttonRef.current && 
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white dark:bg-primary-gunmetal backdrop-blur-sm border-b border-gray-200 dark:border-primary-eggplant transition-colors">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <Link to="/" className="flex items-center space-x-4">
            <Logo />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link 
              to="/" 
              className="font-outfit text-sm font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
              aria-label="Home"
            >
              <Home className="w-5 h-5" />
            </Link>
            <Link 
              to="/hi" 
              className="font-outfit text-sm font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors flex items-center gap-2"
            >
              HI <Brain className="w-4 h-4" />
            </Link>
            <Link 
              to="/services" 
              className="font-outfit text-sm font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
            >
              Services
            </Link>
            <Link 
              to="/case-studies" 
              className="font-outfit text-sm font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
            >
              Case studies
            </Link>
            <Link 
              to="/about" 
              className="font-outfit text-sm font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
            >
              About
            </Link>
            <ThemeToggle />
            <Link to="/contact">
              <Button variant="primary">Contact us</Button>
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center space-x-4">
            <ThemeToggle />
            <button
              ref={buttonRef}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div 
            ref={menuRef}
            className="md:hidden absolute top-20 left-0 right-0 bg-white dark:bg-primary-gunmetal border-b border-gray-200 dark:border-primary-eggplant shadow-lg transition-colors"
          >
            <div className="px-4 py-6 space-y-5">
              <Link
                to="/"
                className="flex items-center space-x-2 font-outfit text-base font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                <Home className="w-5 h-5" />
                <span>Home</span>
              </Link>
              <Link
                to="/hi"
                className="flex items-center space-x-2 font-outfit text-base font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                <span>HI</span>
                <Brain className="w-4 h-4" />
              </Link>
              <Link
                to="/services"
                className="block font-outfit text-base font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                Services
              </Link>
              <Link
                to="/case-studies"
                className="block font-outfit text-base font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                Case studies
              </Link>
              <Link
                to="/about"
                className="block font-outfit text-base font-medium text-primary-gunmetal dark:text-white hover:text-primary-raspberry dark:hover:text-primary-raspberry transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                About
              </Link>
              <div className="pt-3">
                <Link
                  to="/contact"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Button variant="primary" className="w-full">Contact us</Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}
