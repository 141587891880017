import { motion } from 'framer-motion';
import { Brain, ArrowUpRight, CheckCircle2 } from 'lucide-react';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import { AnimatedBackground } from '../../components/AnimatedBackground';

export function AIStrategy() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="bg-primary-gunmetal text-white relative h-[600px]">
        <AnimatedBackground>
          <div className="py-24 h-full">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full">
              <div className="grid lg:grid-cols-2 gap-12 items-center h-full">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className="bg-primary-raspberry/10 rounded-2xl p-4 w-16 h-16 flex items-center justify-center mb-6">
                    <Brain className="w-8 h-8 text-primary-raspberry" />
                  </div>
                  <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
                    AI strategy & consulting
                  </h1>
                  <p className="mt-6 font-opensans text-lg text-gray-200 max-w-xl">
                    Transform your business with a comprehensive AI strategy tailored to your unique needs and objectives.
                  </p>
                  <div className="mt-10">
                    <Link to="/contact">
                      <Button variant="secondary" size="lg" className="group">
                        Schedule a strategy session
                        <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                      </Button>
                    </Link>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </AnimatedBackground>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-16">
            {/* Left Column */}
            <div>
              <h2 className="font-outfit text-3xl font-bold text-primary-gunmetal dark:text-white mb-6">
                Comprehensive AI Strategy Development
              </h2>
              <p className="font-opensans text-gray-600 dark:text-gray-300 mb-8">
                Our AI strategy consulting service helps organizations identify, prioritize, and implement artificial intelligence solutions that drive business value. We work closely with your team to develop a tailored roadmap that aligns with your business objectives and technical capabilities.
              </p>
              
              <div className="space-y-6">
                {[
                  {
                    title: "Business Analysis",
                    description: "Thorough assessment of your current operations, challenges, and opportunities for AI implementation."
                  },
                  {
                    title: "Technology Assessment",
                    description: "Evaluation of your existing infrastructure and recommendations for necessary upgrades or changes."
                  },
                  {
                    title: "ROI Modeling",
                    description: "Detailed analysis of potential returns and benefits from AI implementation across different scenarios."
                  },
                  {
                    title: "Implementation Planning",
                    description: "Step-by-step roadmap for successful AI integration, including timelines and resource requirements."
                  }
                ].map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className="flex items-start space-x-4"
                  >
                    <CheckCircle2 className="w-6 h-6 text-primary-raspberry flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-2">
                        {item.title}
                      </h3>
                      <p className="font-opensans text-gray-600 dark:text-gray-300">
                        {item.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-8">
              <div className="bg-white dark:bg-primary-gunmetal rounded-xl p-8 shadow-soft">
                <h3 className="font-outfit text-2xl font-bold text-primary-gunmetal dark:text-white mb-6">
                  Our Process
                </h3>
                <div className="space-y-6">
                  {[
                    {
                      step: "1",
                      title: "Discovery",
                      description: "Initial consultation to understand your business goals and current AI readiness."
                    },
                    {
                      step: "2",
                      title: "Analysis",
                      description: "Deep dive into your operations, data, and technical infrastructure."
                    },
                    {
                      step: "3",
                      title: "Strategy Development",
                      description: "Creation of a comprehensive AI strategy and implementation roadmap."
                    },
                    {
                      step: "4",
                      title: "Validation",
                      description: "Review and refinement of the strategy with key stakeholders."
                    }
                  ].map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="flex items-start space-x-4"
                    >
                      <div className="bg-primary-raspberry/10 rounded-full w-8 h-8 flex items-center justify-center flex-shrink-0">
                        <span className="font-outfit font-semibold text-primary-raspberry">
                          {item.step}
                        </span>
                      </div>
                      <div>
                        <h4 className="font-outfit text-lg font-semibold text-primary-gunmetal dark:text-white mb-2">
                          {item.title}
                        </h4>
                        <p className="font-opensans text-gray-600 dark:text-gray-300">
                          {item.description}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>

              <div className="bg-gradient-primary rounded-xl p-8 text-white">
                <h3 className="font-outfit text-2xl font-bold mb-4">
                  Ready to get started?
                </h3>
                <p className="font-opensans mb-6">
                  Let's discuss how we can help you develop a winning AI strategy.
                </p>
                <Link to="/contact">
                  <Button variant="secondary" className="w-full group">
                    Contact us
                    <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}