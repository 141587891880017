import { cn } from '../lib/utils';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline';
  size?: 'sm' | 'md' | 'lg';
}

export function Button({
  className,
  variant = 'primary',
  size = 'md',
  children,
  ...props
}: ButtonProps) {
  return (
    <button
      className={cn(
        'inline-flex items-center justify-center rounded-lg font-montserrat font-semibold transition-all duration-300',
        {
          'bg-primary-raspberry text-white hover:bg-primary-raspberry/90 shadow-soft': variant === 'primary',
          'bg-primary-amethyst text-white hover:bg-primary-amethyst/90 shadow-soft': variant === 'secondary',
          'border-2 border-current bg-transparent text-primary-raspberry dark:text-white hover:bg-primary-raspberry/5 dark:hover:bg-white/5': variant === 'outline',
          'px-4 py-2 text-sm': size === 'sm',
          'px-6 py-2.5 text-base': size === 'md',
          'px-8 py-3 text-lg': size === 'lg',
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}