import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowUpRight } from 'lucide-react';
import { Button } from '../../components/Button';
import { AnimatedBackground } from '../../components/AnimatedBackground';

const caseStudies = [
  {
    title: "Badminton Horse Trials Alexa Skill",
    description: "Creating an innovative voice-enabled experience for equestrian fans to access live event updates and streaming media through Alexa.",
    challenge: "Badminton Horse Trials needed a modern way to deliver real-time event information and audio content to their global audience.",
    image: "/images/case-studies/badminton.jpg",
    industry: "Sports & Entertainment",
    slug: "badminton-trials"
  },
  {
    title: "Drupal AI tools for Audley",
    description: "Enhancing content management and user experience through AI-powered tools integrated into Audley Villages' Drupal-based website.",
    challenge: "Audley Villages sought to enhance user engagement and streamline content management on their digital platforms.",
    image: "/images/case-studies/audley.jpg",
    industry: "Retirement living",
    slug: "audley-villages"
  },
  {
    title: "Marketing automation for Guides for Brides",
    description: "Streamlining content workflows and improving SEO performance through intelligent automation for the UK's leading wedding platform.",
    challenge: "Guides for Brides aimed to optimise their marketing and SEO processes to improve search engine rankings and operational efficiency.",
    image: "/images/case-studies/guides-for-brides.jpg",
    industry: "Wedding industry",
    slug: "guides-for-brides"
  },
  {
    title: "Custom GPTs for content generation",
    description: "Developing specialized GPT models for automated content creation, enhancing productivity and maintaining brand consistency.",
    challenge: "Businesses needed efficient ways to generate high-quality, consistent content while maintaining their unique brand voice.",
    image: "/images/case-studies/custom-gpts.jpg",
    industry: "AI & Content",
    slug: "custom-gpts"
  },
  {
    title: "Open Access Oxford",
    description: "Using AI to analyse user behaviour patterns and improve content discovery across Oxford's open access platforms.",
    challenge: "Open Access Oxford needed to better understand user behavior and improve content discoverability.",
    image: "/images/case-studies/oxford.jpg",
    industry: "Education",
    slug: "open-access-oxford"
  },
  {
    title: "MHR Development Enhancement",
    description: "Enhancing platform capabilities through AI-powered development solutions and system optimisation.",
    challenge: "MHR aimed to enhance the functionality and performance of their digital platforms.",
    image: "/images/case-studies/mhr.jpg",
    industry: "Software development",
    slug: "mhr-development"
  }
];

export function CaseStudies() {
  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="relative bg-primary-amethyst text-white py-24 overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-20"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl"
          >
            <h1 className="font-outfit text-4xl md:text-5xl lg:text-6xl font-bold leading-tight">
              Success stories: AI in action
            </h1>
            <p className="mt-6 font-opensans text-lg text-gray-200">
              Explore our collection of case studies showcasing how we've helped businesses across various industries harness the power of AI to drive innovation, improve efficiency, and achieve measurable results.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Case Studies Grid */}
      <section className="py-24 bg-white dark:bg-primary-gunmetal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="font-outfit text-3xl font-bold text-primary-gunmetal dark:text-white mb-6">
              Featured case studies
            </h2>
            <p className="font-opensans text-lg text-gray-600 dark:text-gray-300">
              From voice technology to content automation, discover how our AI solutions have transformed businesses across different sectors.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {caseStudies.map((study, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="group bg-white dark:bg-primary-gunmetal rounded-xl overflow-hidden shadow-soft hover:shadow-xl transition-all duration-300"
              >
                <div className="relative h-48 overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-primary opacity-10 group-hover:opacity-20 transition-opacity"></div>
                  <img 
                    src={study.image} 
                    alt={study.title} 
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
                  />
                </div>
                <div className="p-6">
                  <div className="inline-block px-4 py-1 rounded-full bg-primary-raspberry/10 text-primary-raspberry font-opensans text-sm font-medium mb-4">
                    {study.industry}
                  </div>
                  <h3 className="font-outfit text-xl font-semibold text-primary-gunmetal dark:text-white mb-4">
                    {study.title}
                  </h3>
                  <p className="font-opensans text-primary-gunmetal dark:text-gray-200 mb-6">
                    {study.description}
                  </p>
                  <Link to={`/case-studies/${study.slug}`}>
                    <Button variant="primary" size="sm" className="group/btn w-full">
                      Read case study
                      <ArrowUpRight className="ml-2 w-4 h-4 transition-transform group-hover/btn:translate-x-1 group-hover/btn:-translate-y-1" />
                    </Button>
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-primary text-white relative overflow-hidden">
        <div className="absolute inset-0 bg-diagonal-light opacity-10"></div>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative">
          <h2 className="font-outfit text-4xl font-bold mb-6">
            Ready to achieve similar results?
          </h2>
          <p className="font-opensans text-lg text-gray-100 mb-10">
            Let's discuss how we can help you leverage AI to transform your business.
          </p>
          <Link to="/contact">
            <Button variant="secondary" size="lg" className="group">
              Start your AI journey
              <ArrowUpRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
}